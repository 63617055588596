


















































import AppLoading from '@/components/ui/AppLoading.vue'
import { Component, Vue } from 'vue-property-decorator'
import { ConsultarNFUseCase } from '@/usecases/consulta/nf/ConsultarNFUseCase'
import { DadosConsultaNF } from '@/models/DadosConsultaNF'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { imprimirNfceDaConsulta } from '@/shareds/venda-shareds'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		AppLoading,
	},
})
export default class ConsultaNF extends Vue {
	loading = true
	imprimindo = false
	dadosConsulta: DadosConsultaNF | null = null
	consultarNFUseCase: ConsultarNFUseCase = new ConsultarNFUseCase()

	dateTimeToPtBrFormat = dateTimeToPtBrFormat

	async created() {
		this.consultarNFUseCase.execute(this.$route.params.chaveDeConsulta)
			.then(dados => this.dadosConsulta = dados)
			.finally(() => this.loading = false)
	}

	async imprimirCupom() {
		if (this.dadosConsulta) {
			this.imprimindo = true
			imprimirNfceDaConsulta(this.$route.params.chaveDeConsulta, this.dadosConsulta.chaveNfceGerada)
				.finally(() => this.imprimindo = false)
		} else {
			AlertModule.setError('Problema com a impressão, entre em contato com a loja emissora.')
		}
	}
}
